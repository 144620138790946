import React, {useEffect, useState} from 'react';
import main from './img/main.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import AcceptanceForm from "./AcceptanceForm";
import WhoAreYouForm from "./WhoAreYouForm";
import {Container} from "@material-ui/core";
import GuestsList from "./GuestsList";

function App() {
    return (
        <div className="App">
            <div>
                <img style={{maxWidth: '100%'}} src={main}/>
            </div>
            <Container>
                <Router>
                    <Switch>
                        <Route path="/билеты/:id" children={<AcceptanceForm/>}/>
                        <Route path="/гости" children={<GuestsList/>}/>
                        <Route path="/" children={<WhoAreYouForm/>}/>
                    </Switch>
                </Router>
            </Container>
        </div>
    );
}

export default App;
