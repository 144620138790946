import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    Button,
    Checkbox,
    createStyles,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    withStyles,
    WithStyles
} from "@material-ui/core";
import Ticket from "./Ticket";

export interface Guest {
    id: number,
    firstName: string,
    appeal: string,
    appealPrefix: string,
    gender: string
}

interface Child {
    age: string
}

const styles = createStyles({
    root: {
        '& form': {
            'display': 'flex',
            'flex-direction': 'column',
            '&>div': {
                'margin': 12
            }
        }
    },
});

export interface AcceptanceFormProps extends WithStyles<typeof styles> {
}

function AcceptanceForm(props: AcceptanceFormProps) {
    const {classes} = props;

    const [dialogOpened, setDialogOpened] = useState<boolean>(false)
    const [accepted, setAccepted] = useState<boolean | undefined>(undefined)
    const [guest, setGuest] = useState<Guest | undefined>(undefined)
    const [hasPlusOne, setHasPlusOne] = useState<boolean>(false)
    const [plusOneName, setPlusOneName] = useState<string>("")
    const [hasChildren, setHasChildren] = useState<boolean>(false)
    const [childrenCount, setChildrenCount] = useState<string>("0")
    const [childrenCountInt, setChildrenCountInt] = useState<number>(0)
    const [hasDietSpecial, setHasDietSpecial] = useState<boolean>(false)
    const [diet, setDiet] = useState<any>({})
    const [drinks, setDrinks] = useState<any>({})
    useEffect(() => {
        const preNum = parseInt(childrenCount)
        if (!isNaN(preNum) && Number.isInteger(preNum)) {
            setChildrenCountInt(Math.min(10, preNum))
        }
    }, [childrenCount])
    const [children, setChildren] = useState<Array<Child>>([])
    useEffect(() => {
        setChildren(Array(childrenCountInt).fill(undefined).map((element, index) => {
            if (children[index]) {
                return children[index];
            }
            return {age: "0"}
        }));
    }, [childrenCountInt])
    const onSubmit = () => {
        const form = {
            accepted: accepted,
            guestId: guest?.id,
            hasPlusOne: hasPlusOne,
            plusOneName: plusOneName,
            children: children,
            diet: Object.keys(diet).join(", "),
            drinks: Object.keys(drinks).join(", ")
        }
        console.log(form)
        fetch(`https://wedding-back.onrender.com/acceptances`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        })
            .then(response => setDialogOpened(true))
    };
    const onDietChange = (name: string, checked: boolean) => {
        const items = Object.assign({}, diet)
        items[name] = checked
        setDiet(items)
    }
    const onDrinksChange = (name: string, checked: boolean) => {
        const items = Object.assign({}, drinks)
        items[name] = checked
        setDrinks(items)
    }

    const DietCheckbox = (val: string) => <FormControlLabel value={val} control={<Checkbox onChange={e => onDietChange(e.target.value, e.target.checked)}/>} label={val}/>
    const DrinksCheckbox = (val: string) => <FormControlLabel value={val} control={<Checkbox onChange={e => onDrinksChange(e.target.value, e.target.checked)}/>} label={val}/>

    let {id} = useParams<{ id: string }>();

    useEffect(() => {
        id && fetch(`https://wedding-back.onrender.com/guests/${id}`)
            .then(response => response.json())
            .then(res => (setGuest(res)))
    }, [id])

    return (
        <header className="App-header">
            {guest && (
                <div className={classes.root}>
                    <Ticket guest={guest} />

                    <Paper style={{padding: 16}}>
                        <form onSubmit={onSubmit}>
                            <FormGroup>
                                <FormLabel style={{marginBottom: '1em'}}>Приедете ли вы на нашу свадьбу?</FormLabel>
                                <RadioGroup aria-label="gender" value={accepted} onChange={e => setAccepted(e.target.value === 'true')}>
                                    <FormControlLabel style={{marginBottom: '1em'}} value="true" control={<Radio/>} label="Обязательно, как я могу пропустить такое событие!?"/>
                                    <FormControlLabel value="false" control={<Radio/>} label="К сожалению, не смогу приехать, но все равно поздравляю вас!"/>
                                </RadioGroup>
                            </FormGroup>
                            {accepted && <React.Fragment>
                                <FormGroup>
                                    <FormLabel>Планируете ли вы приехать в паре с гостем/гостьей?</FormLabel>
                                    <FormControlLabel control={<Checkbox
                                        checked={hasPlusOne}
                                        onChange={e => setHasPlusOne(e.target.checked)}
                                    />} label="Я планирую быть +1"/>
                                </FormGroup>
                                {hasPlusOne &&
                                <FormGroup>
                                    <FormLabel>Как зовут вашего гостя/гостью?</FormLabel>
                                    <TextField value={plusOneName} onChange={e => setPlusOneName(e.target.value)} variant="outlined"/>
                                </FormGroup>}
                                <FormGroup>
                                    <FormLabel>Планируете ли вы приехать с ребенком?</FormLabel>
                                    <FormControlLabel control={<Checkbox
                                        checked={hasChildren}
                                        onChange={e => setHasChildren(e.target.checked)}
                                    />} label="Я планирую приехать с ребенком"/>
                                </FormGroup>
                                {hasChildren &&
                                <FormGroup>
                                    <FormLabel>Сколько детей будет с вами?</FormLabel>
                                    <TextField value={childrenCount} onChange={e => setChildrenCount(e.target.value)} variant="outlined" type="number" inputProps={{min: 0, max: 10}}/>
                                </FormGroup>}
                                {hasChildren &&
                                children.map((child, index) => (<FormGroup key={index}>
                                    <FormLabel>Возраст {index + 1} ребёнка</FormLabel>
                                    <TextField value={child.age} onChange={e => {
                                        const items = JSON.parse(JSON.stringify(children));
                                        items[index] = {age: e.target.value};
                                        setChildren(items);
                                    }} variant="outlined" type="number" inputProps={{min: 0, max: 18}}/>
                                </FormGroup>))
                                }
                                <FormGroup>
                                    <FormLabel>У вас (либо у вашего гостя) есть особые предпочтения по меню?</FormLabel>
                                    <FormControlLabel control={<Checkbox
                                        checked={hasDietSpecial}
                                        onChange={e => setHasDietSpecial(e.target.checked)}
                                    />} label="Есть предпочтения"/>
                                </FormGroup>
                                {hasDietSpecial &&
                                <FormGroup>
                                    <FormLabel>Отметьте, пожалуйста, все предпочтения по меню:</FormLabel>
                                    {DietCheckbox("Без лактозы")}
                                    {DietCheckbox("Без глютена")}
                                    {DietCheckbox("Вегетарианское меню")}
                                    {DietCheckbox("Веганское меню")}
                                    {DietCheckbox("Другое (сообщите организаторам об особенностях меню)")}
                                </FormGroup>
                                }
                                <FormGroup>
                                    <FormLabel>Какие у вас предпочтения по напиткам?</FormLabel>
                                    {DrinksCheckbox("Вино красное")}
                                    {DrinksCheckbox("Вино белое")}
                                    {DrinksCheckbox("Шампанское")}
                                    {DrinksCheckbox("Коктейли")}
                                    {DrinksCheckbox("Водка")}
                                    {DrinksCheckbox("Ром")}
                                    {DrinksCheckbox("Виски")}
                                </FormGroup>
                            </React.Fragment>}
                            {typeof accepted == 'undefined' ||
                            <FormGroup>
                                <Button variant="outlined" onClick={onSubmit}>Отправить</Button>
                            </FormGroup>
                            }
                        </form>
                        <Dialog
                            open={dialogOpened}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {accepted && (
                                <React.Fragment>
                                    <DialogTitle id="alert-dialog-title">Ваш билет зарегистрирован</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Будем рады видеть вас 17.07.2021 в ресторане Атлантис!
                                        </DialogContentText>
                                    </DialogContent>
                                </React.Fragment>
                            ) || (
                                <React.Fragment>
                                    <DialogTitle id="alert-dialog-title">Ваш билет зарегистрирован</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Жаль, что вы не сможете приехать, приезжайте в гости!
                                        </DialogContentText>
                                    </DialogContent>
                                </React.Fragment>
                            )}

                        </Dialog>
                    </Paper>
                </div>
            )}
        </header>
    );
}

export default withStyles(styles)(AcceptanceForm);
