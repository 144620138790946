import React, {useEffect, useState} from 'react';
import {DataGrid, GridCellParams} from '@material-ui/data-grid';
import {Container, Tooltip} from "@material-ui/core";
import QRCode from "qrcode.react";

interface Guest {
    id: number,
    firstName: string,
    lastName: string,
    partner: string,
    appeal: string,
    side: string,
    status: string
}

function createQr(params: GridCellParams) {
    return (
        <div style={{width: 300, height: 300}}><QRCode size={300} value={`https://свадьба-17-июля.рф/билеты/${params.row.id}`}/></div>
    )
}

const columns = [
    {field: 'id', headerName: 'ID', width: 80},
    {field: 'firstName', headerName: 'Имя', flex: 1},
    {field: 'lastName', headerName: 'Фамилия', flex: 1},
    {field: 'partner', headerName: 'Партнёр', flex: 1},
    {field: 'appeal', headerName: 'Обращение', flex: 1, renderCell: (params: GridCellParams) => <div><Tooltip title={params.row.appeal}><span>{params.row.appeal}</span></Tooltip></div>},
    {field: 'side', headerName: 'Сторона', flex: 1},
    {field: 'status', headerName: 'Статус', flex: 1},
    {field: 'qr', headerName: 'QR', renderCell: createQr, width: 328},

]

function GuestsList() {
    const [guests, setGuests] = useState<Array<Guest>>([])

    useEffect(() => {
        fetch(`https://wedding-back.onrender.com/guests`)
            .then(response => response.json())
            .then(res => (setGuests(res)))
    }, [])

    return (
        <Container>
            <div style={{height: '100%', backgroundColor: '#CCCCCCCC'}}>
                <DataGrid rows={guests} columns={columns} pageSize={50} rowHeight={328} autoHeight/>
            </div>
        </Container>
    )
}

export default GuestsList;
