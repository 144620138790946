import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {TextField} from "@material-ui/core";

interface Guest {
    firstName: string
}

function WhoAreYouForm() {
    const [guest, setGuest] = useState<Guest | undefined>(undefined)

    let {id} = useParams<{ id: string }>();

    useEffect(() => {
        id && fetch(`https://wedding-back.onrender.com/guests/${id}`)
            .then(response => response.json())
            .then(res => (setGuest(res)))
    }, [id])

    const history = useHistory();

    const onChange = (value: string) => {
        if (value.length > 3) {
            history.push(`/билеты/${value}`);
        }
    }

    return (
        <header className="App-header">
            <h1>Привет!</h1>
            <p>Введи номер билета:</p>
            <TextField onChange={e => {
                onChange(e.target.value)
            }}/>
        </header>
    );
}

export default WhoAreYouForm;
