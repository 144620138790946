import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import man from './img/man.png';
import cat from './img/cat.png';
import rest from './img/rest.png';
import phone from './img/phone.png';
import present from './img/present.png';
import {Guest} from "./AcceptanceForm";
import React from "react";

const styles = (theme: Theme) => createStyles({
    root: {
        padding: '3rem 0 0 0',
        '& ul': {
            listStyle: 'none',
            margin: 0,
            padding: 0,
            '& li': {
                margin: 0
            }
        },
        '& a': {
            color: theme.palette.text.primary,
            textDecoration: 'none'
        }
    },
    header: {
        fontFamily: "Romul",
        fontSize: '28px',
        marginBottom: '1rem'
    },
    header2: {
        fontFamily: "Romul",
        fontSize: '20px',
        marginBottom: '.6rem'
    },
    header3: {
        fontFamily: "Romul",
        fontSize: '16px'
    },
    items: {
        display: "flex",
        justifyContent: "space-between",
        flexFlow: "wrap",
        fontFamily: 'Mysl',
        fontStyle: 'italic',
        fontSize: '22px',
        margin: '2rem 0',
        '&>div.flex': {
            display: 'flex',
            alignItems: 'center',
            '&>img': {
                height: '120px',
                marginRight: '1rem'
            },
            '&>div': {
                width: '160px'
            },
        },
    },
    man: {},
    cat: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: "row-reverse"
        }
    },
    rest: {
        display: 'block',
        marginTop: '2rem',
        textAlign: 'center',
        '& img': {
            margin: '1rem 0',
            maxHeight: '120px',
            maxWidth: '100%',
            marginRight: '1rem'
        },
    },
    phone: {
        marginTop: '2rem',
        '& img': {
            maxHeight: '30px',
            maxWidth: '100%',
        },
        '&>div': {
            width: 'auto !important'
        },
    },
    surprise: {
        marginTop: '2rem',
        '& img': {
            maxHeight: '30px',
            maxWidth: '100%',
        },
        '&>div': {
            width: 'auto !important'
        },
    }
});

export interface TicketProps extends WithStyles<typeof styles> {
    guest: Guest
}

function declension(gender: string, a1: string, a2: string, a3: string) {
    switch (gender) {
        case 'male':
            return a1;
        case 'female':
            return a2;
        default:
        case 'multiple':
            return a3;
    }
}

function pronoun(appealPrefix: string, a1: string, a2: string, a3: string) {
    switch (appealPrefix) {
        case 'ты':
            return a1;
        case 'вы':
            return a2;
        default:
        case 'Вы':
            return a3;
    }
}

function Ticket(props: TicketProps) {
    const {classes, guest} = props;

    return <div className={classes.root}>
        <div className={classes.header}>Привет, {guest.appeal}!</div>
        <div className={classes.header2}>Мы приглашаем {pronoun(guest.appealPrefix, 'тебя', 'вас', 'Вас')} на свадьбу!</div>
        <div className={classes.header3}>(сейчас {declension(guest.gender, 'сделай', 'сделай', 'сделайте')} вид,
            что {guest.appealPrefix} {declension(guest.gender, 'удивлён', 'удивлена', 'удивлены')} и не {declension(guest.gender, 'ожидал', 'ожидала', 'ожидали')} этого)
        </div>
        <div className={classes.items}>
            <div className={classes.man + ' flex'}>
                <img src={man}/>
                <div>Одевайся как будто ты едешь на свадьбу</div>
            </div>
            <div className={classes.cat + ' flex'}>
                <img src={cat}/>
                <div>Не дари цветы, у нашего кота на них аллергия</div>
            </div>
            <div className={classes.rest}>
                <div>Предъявителю билета будет подан экипаж,</div>
                <img src={rest}/>
                <div>который отвезёт всех в лес</div>
                <div>(там находится ресторан)</div>
            </div>
            <div className={classes.phone + ' flex'}>
                <img src={phone}/>
                <div>В свадебных костюмах нет карманов,
                    поэтому {declension(guest.gender, 'звони', 'звони', 'звоните')} организаторам:
                    <ul>
                        <li>Екатерина <a href="tel:+79210993041">+7 (921) 099-30-41</a></li>
                        <li>Юлия <a href="tel:+79062864096">+7 (906) 286-40-96</a></li>
                    </ul>
                </div>
            </div>
            <div className={classes.surprise + ' flex'}>
                <img src={present}/>
                <div>ЗЫ: Кстати, о сюрпризах тоже лучше предупредить Катю или Юлю.
                </div>
            </div>
        </div>
        <p>
            Мы рады, что {guest.appealPrefix} {declension(guest.gender, 'зашёл', 'зашла', 'зашли')} на эту страницу. Осталось немного:&nbsp;
            {declension(guest.gender, 'ответь', 'ответь', 'ответьте')}, пожалуйста, на несколько
            вопросов о {pronoun(guest.appealPrefix, 'тебе', 'вас', 'Вас')} и {pronoun(guest.appealPrefix, 'твоих', 'ваших', 'Ваших')} планах:
        </p>
    </div>
}

export default withStyles(styles)(Ticket);